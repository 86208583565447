import { Component, Input, OnInit } from '@angular/core';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { NGXLogger } from 'ngx-logger';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss']
})
export class QRCodeScannerComponent implements OnInit {
  resizeSubscription!: Subscription;
  windowWidth: number = window.innerWidth;

  constructor(private logger: NGXLogger) {}
  @Input() classNamesToAdd: string = 'mx-auto';
  @Input() widthInPixels: number = 600;
  @Input() heightInPixels: number = 500;
  @Input() qrBoxWidthInPixels: number = 250;
  @Input() qrBoxHeightInPixels: number = 250;

  private Html5QrcodeScanner: Html5QrcodeScanner;

  ngOnInit(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.onResize();
      });

    this.Html5QrcodeScanner = new Html5QrcodeScanner(
      'qr-code-scanner',
      {
        fps: 10,
        qrbox: {
          width: this.qrBoxWidthInPixels,
          height: this.qrBoxHeightInPixels
        }
      },
      true
    );
    this.Html5QrcodeScanner.render(this.onScanSuccess.bind(this), this.onScanFailure.bind(this));
  }

  onResize(): void {
    // Logic to handle resize event
    this.windowWidth = window.innerWidth;
    if (this.widthInPixels > this.windowWidth) {
      this.widthInPixels = this.windowWidth;
      this.heightInPixels = this.widthInPixels * (this.heightInPixels / this.widthInPixels);
      this.Html5QrcodeScanner.render(this.onScanSuccess.bind(this), this.onScanFailure.bind(this));
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from the resize event to prevent memory leaks
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    this.logger.log(`Code matched = ${decodedText}`, decodedResult);
  }

  onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    this.logger.warn(`Code scan error = ${error}`);
  }
}
