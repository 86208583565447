import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { PickOrderService, PickOrderCheckInCheckOutService } from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { BreadcrumbsService } from '@wo-app/breadcrumbs/shared/services';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService, CachedDataService, ModalService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-scan-qrcode-in-pick-order',
  templateUrl: './scan-qrcode-in-pick-order.component.html',
  styleUrl: './scan-qrcode-in-pick-order.component.scss'
})
export class ScanQRCodeInPickOrderComponent extends EntityBaseComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private pickOrderService: PickOrderService,
    private pickOrderCheckInCheckOutService: PickOrderCheckInCheckOutService,
    public cachedDataService: CachedDataService,
    private loadingBar: LoadingBarService,
    breadcrumbService: BreadcrumbsService,
    titleService: Title,
    private modalService: ModalService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.PICKORDERS, router, route, impersonationService, logger, breadcrumbService, titleService);
    this.editMode = true;
  }

  ngOnInit(): void {}
}
