<div class="container">
    <div class="row">
        <div class="col">
            <h1 class="text-center">Scan Item QR Code</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <app-qrcode-scanner class="qr-code-scanner" class="pe-4" [classNamesToAdd]="" [widthInPixels]="600" [heightInPixels]="500"></app-qrcode-scanner>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <div class="mw-50 d-inline-block mx-auto">
                <h4 class="text-center">OR enter Wingman ID Manually</h4>
                <p>Enter item ID number on the item's printed label to confirm it matches the order.</p>
                <p class="small">Note: the item ID is below the item name on the label. It is 6 characters long and should start with the letter S, T, or E.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center">
            <div class="mw-50 d-block mx-auto">
                <label for="wingmanId" class="form-label">Wingman ID</label>
                <input type="text" class="form-control" placeholder="ex: T00002">
                <button class="btn btn-secondary" [routerLink]="'/pick-orders/' + this.id + '/pull-or-return'">Cancel</button>
                <button class="btn btn-primary">Confirm</button>
            </div>
        </div>
    </div>
</div>