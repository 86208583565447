import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  OrganizationService,
  QueueOrganizationAccountCreationCommand,
  ReadOrganizationCommandResult,
  UpdateOrganizationCommand,
  UpdateOrganizationCommandResult
} from '@wo-api/index';
import { EntityGlobals } from '@wo-app/app.global';
import { ToastService } from '@wo-app/core/common/toast-message/shared/services';
import { ImpersonationService } from '@wo-app/core/services';
import { EntityBaseComponent } from '@wo-app/shared/models';
import { StringUtils } from '@wo-app/shared/utils';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss']
})
export class OrganizationDetailComponent extends EntityBaseComponent implements OnInit {
  override showForm: boolean;
  override organizationId: number;
  readOrganizationCommandResult: ReadOrganizationCommandResult = {};
  dataModel: ReadOrganizationCommandResult;
  updateOrganizationCommand: UpdateOrganizationCommand = {};
  createOrganizationCommand: QueueOrganizationAccountCreationCommand = {};
  form: FormGroup;
  originalFormState: FormGroup;
  fromKey: string;

  constructor(
    private impersonationService: ImpersonationService,
    private logger: NGXLogger,
    private organizationService: OrganizationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private toastService: ToastService
  ) {
    super(EntityGlobals.ORGANIZATIONS, router, route, impersonationService, logger);
    this.createForm();
    this.organizationId = this.route.snapshot.params['id'];
    this.logger.debug(this.organizationId);
    this.fromKey = 'FormModel';
    if (this.editMode) {
      this.getData();
    } else {
      this.showForm = true;
    }
  }

  convertServerDataToFormModel(result: ReadOrganizationCommandResult) {
    this.dataModel = result;
    this.logger.debug(this.dataModel.accountActiveDate);
    if (this.dataModel.accountActiveDate !== undefined) {
      //this.dataModel.accountActiveDate = new Date(this.dataModel.accountActiveDate);
    }
    if (this.dataModel.freeTrialEndDate !== undefined) {
      //this.dataModel.freeTrialEndDate = new Date(this.dataModel.freeTrialEndDate);
    }
    this.dataModel.customCdnDomain = this.dataModel.applicationDomains[0];
    this.form.patchValue(this.dataModel);
    if (this.originalFormState.pristine) {
      this.logger.debug('Setting Original Form State Value');
      this.originalFormState.patchValue(this.dataModel);
    }
  }

  public refreshDeploymentData(): void {}

  getData() {
    this.refreshDeploymentData();
    this.organizationService.read(this.id, true).subscribe((result: ReadOrganizationCommandResult) => {
      this.convertServerDataToFormModel(result);
      this.showForm = true;
    });
  }

  public startDeployment(applicationBuildId: number) {}

  ngOnInit() {}

  dataStateChange($event) {}

  copyFromBillingAddress() {
    const billingAddress = this.form.value.billingAddress;
    this.form.controls['mailingAddress'].patchValue(billingAddress);
  }

  copyFromMailingAddress() {
    const mailingAddress = this.form.value.mailingAddress;
    this.form.controls['billingAddress'].patchValue(mailingAddress);
  }

  resetForm() {
    this.logger.debug(this.originalFormState);
    this.form.reset(this.originalFormState.value);
    this.router.navigate(['/organizations']);
  }
  createForm() {
    this.form = this.fb.group({
      id: [''],
      name: ['', [Validators.required, Validators.minLength(5)]],
      CustomEmail: [''],
      createAdminUser: [''],
      adminFirstName: [''],
      adminLastName: [''],
      adminUsername: [''],
      adminPassword: [''],
      adminEmailAddress: [''],
      accountActiveDate: [''],
      freeTrialEndDate: [''],
      mailingAddress: this.fb.group({
        address1: [''],
        address2: [''],
        city: [''],
        state: [''],
        zipCode: ['']
      }),
      billingAddress: this.fb.group({
        address1: [''],
        address2: [''],
        city: [''],
        state: [''],
        zipCode: ['']
      }),
      customCdnDomain: [''],
      s3BucketDnsName: [''],
      s3BucketName: [''],
      cloudFrontDistributionId: [''],
      cloudFrontDistributionDomain: [''],
      organizationKey: [''],
      accountLimits: this.fb.group({
        locations: [''],
        buildings: [''],
        rooms: [''],
        courses: [''],
        patients: [''],
        checklists: [''],
        inventoryItems: [''],
        simMaintenanceTasks: [''],
        labMaintenanceTasks: [''],
        scenarios: [''],
        skills: [''],
        manikinInventoryItems: [''],
        manikinInventoryItemProcedures: [''],
        communicationBoardActionItems: [''],
        users: ['']
      }),
      metadata: this.fb.group({
        created: [''],
        createByUsername: [''],
        createdByUserId: [''],
        lastUpdated: [''],
        lastUpdatedByUsername: [''],
        lastUpdatedByUserId: ['']
      })
    });
    this.originalFormState = this.form;

    if (!this.editMode) {
      const nameControl = this.form.get('name');
      const organizationKeyControl = this.form.get('organizationKey');
      nameControl.valueChanges.forEach((value: string) => {
        const organizationKey = StringUtils.CreateOrganizationKey(value);
        organizationKeyControl.patchValue(organizationKey);
      });
    }
  }

  onSubmit(closeOnSuccess) {
    // If the Form is in Edit Mode, Update Object
    if (this.editMode) {
      this.updateOrganizationCommand = this.form.value;
      this.logger.debug(this.updateOrganizationCommand);
      this.organizationService
        .update(this.updateOrganizationCommand.id.toString(), this.updateOrganizationCommand)
        .subscribe((result: UpdateOrganizationCommandResult) => {
          this.logger.debug(result);
          if (closeOnSuccess) {
            this.router.navigate(['/organizations']);
          } else {
            this.convertServerDataToFormModel(result);
            this.toastService.success('Success!', 'This item has been saved.');
          }
        });
    } else {
      // Otherwise, create a new object
      this.createOrganizationCommand = this.form.value;
      this.logger.debug(this.createOrganizationCommand);
      this.organizationService.queueCreation(this.createOrganizationCommand).subscribe(result => {
        this.convertServerDataToFormModel(result);
        this.router.navigate(['/organizations/' + result]);
        this.editMode = true;
        this.toastService.success('Success!', 'This item has been saved.');
      });
    }
  }
  submitAndClose() {
    this.onSubmit(true);
  }
  disable() {}

  delete() {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Please confirm',
      content: 'Are you sure you want to delete this item?',
      actions: [{ text: 'No' }, { text: 'Yes', primary: true }],
      width: 450,
      height: 200,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        this.logger.debug('close');
      } else {
        this.logger.debug('action', result);
      }
      this.toastService.success('Oops.', 'This item has been saved.');
    });
  }
}
