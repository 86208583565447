@if(ListPickOrderInventoryListQueryResult != null){
    <h2>Pick Order #{{ this.id }}</h2>
    <div class="form">
        <div class="row">
            <div class="col-2">
                Date Needed:
            </div>
            <div class="col">
                {{ ListPickOrderInventoryListQueryResult.pickOrderPickListPickOrder.dateNeeded | amCalendar }}
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                Date of Return:
            </div>
            <div class="col">
                {{ ListPickOrderInventoryListQueryResult.pickOrderPickListPickOrder.dateOfReturn | amCalendar }}
            </div>
        </div>
        <div class="row">
            <div class="col-2">
                Assigned To:
            </div>
            <div class="col">
                {{ ListPickOrderInventoryListQueryResult.pickOrderPickListAssignedTo.assignedToId }}
            </div>
        </div>
        <div class="row instructions mt-1">
            <div class="col">
                <div class="py-2">
                    <h3 class="mb-1"><i class="fa fa-qrcode"></i> Instructions</h3>
                    <p class="mb-0">Once an item is located, press the "pick" button to scan its QR Code or enter its ID# to pick it for the order.</p>
                </div>
            </div>
        </div>
        <div *ngFor="let item of ListPickOrderInventoryListQueryResult.lineItems" class="row">
            <div class="col">
                <div class="row room-title">
                    <div class="col">
                        <h5 class="py-2 my-0">{{item.itemName1}}</h5>
                    </div>
                </div>
                <div *ngFor="let item of item.rooms" class="row">
                    <div class="col">
                        <div class="row room-inventory-type-title mb-0">
                            <div class="col">
                                <h6 class="py-2 my-0">{{item.roomId}} {{item.roomName}}</h6>
                            </div>
                        </div>
                        <div *ngFor="let item of item.inventoryInRooms" class="row line-item py-2">
                            <div class="col d-flex align-items-center">
                                <input class="form-check-input" type="checkbox">
                            </div>
                            <div class="col">
                                <small class="d-block">Location</small>
                                <p class="mb-0">{{item.dimension1}}/{{item.dimension2}}/{{item.dimension3}}</p>
                            </div>
                            <div class="col">
                                <small class="d-block">Available</small>
                                <p class="mb-0">{{item.quantityAvailable}}</p>
                            </div>
                            <div class="col">
                                <small class="d-block">Picked</small>
                                <p class="mb-0">{{item.quantityPicked}}</p>
                            </div>
                            <div class="col d-flex align-items-center">
                                <div class="dropdown">
                                    <button class="btn btn-unstyled dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa-thin fa-ellipsis-vertical"></i>
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">View item details</a></li>
                                      <li><a class="dropdown-item" href="#">Pick item</a></li>
                                      <li><a class="dropdown-item" href="#">Report an issue</a></li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            
        </div>
    </div>
}