<form [formGroup]="form" (ngSubmit)="onSubmit(false)" [hidden]="!showForm" class="entity-form">
    <div class="row">
        <div class="col-sm-12 col-md-6" [hidden]="editMode">
            <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Create Organization</button>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <kendo-buttongroup>
                <button kendoButton type="submit" [disabled]="!form.valid" [primary]="true" [icon]="'save'">Save and Continue</button>
                <button kendoButton type="button" (click)="submitAndClose()" [primary]="true" [disabled]="!form.valid" [icon]="'save'">Save and Close</button>
                <button kendoButton type="button" (click)="resetForm()" [primary]="false" [icon]="'cancel-circle'">Cancel</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12 col-md-6" [hidden]="!editMode">
            <div class="float-end">
                <kendo-buttongroup>
                    <button kendoButton type="button" (click)="disable()" [icon]="'cancel-circle'">Disable Organization</button>
                    <button kendoButton type="button" (click)="delete()" [icon]="'delete'">Delete Organization</button>
                </kendo-buttongroup>
                <div kendoDialogContainer></div>
            </div>
        </div>
        <div class="col-sm-12" [hidden]="true">
            <kendo-buttongroup>
                <button kendoButton type="button" [primary]="true" [icon]="'user'">View Users</button>
            </kendo-buttongroup>
        </div>
        <div class="col-sm-12">
            <hr/>
        </div>
    </div>
    <app-validation-errors></app-validation-errors>
    <input type="hidden" id="id" formControlName="id" />
    <kendo-tabstrip>
        <kendo-tabstrip-tab [title]="'General'" [selected]="true">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class=" col-sm-12">
                        <label for="name" class="col-form-label">Organization Name</label>
                        <input kendoTextBox formControlName="name" id="name" class="form-control" placeholder="Organization Name" />
                    </div>
                    <div class=" col-md-12">
                        <label for="organizationKey" class="col-form-label">Organization Key</label>
                        <input kendoTextBox type="text" readonly formControlName="organizationKey" id="organizationKey" class="form-control" placeholder="Organization Key">
                    </div>
                    <div class=" col-md-12">
                        <label for="CustomEmail" class="col-form-label">Custom Email</label>
                        <input kendoTextBox formControlName="CustomEmail" id="CustomEmail" class="form-control" placeholder="Custom Email">
                    </div>
                    <div class=" col-sm-6">
                        <label for="AccountActiveDate" class="col-form-label">Account Active Date</label>
                        <kendo-datepicker formControlName="accountActiveDate" id="AccountActiveDate" class="form-control"></kendo-datepicker>
                    </div>
                    <div class=" col-sm-6">
                        <label for="FreeTrialEndDate" class="col-form-label">Free Trial End Date</label>
                        <kendo-datepicker formControlName="freeTrialEndDate" id="FreeTrialEndDate" class="form-control"></kendo-datepicker>
                    </div>
                </div>
                <div class="row" [hidden]="editMode">
                    <div class="col-sm-12">
                        <h4>Admin User Information</h4>
                        <div class="form-check">
                            <label class="form-check-label">
                                <input type="checkbox"class="form-check-input" [value]="'true'" formControlName="createAdminUser">
                                Should a User Account Be Created for this Organization?
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12" [hidden]="!form.controls['createAdminUser'].value">
                        <div class="row">
                            <div class=" col-md-6">
                                <label for="adminFirstName" class="col-form-label">Admin User First Name</label>
                                <input kendoTextBox type="text" formControlName="adminFirstName" id="adminFirstName" class="form-control" placeholder="Admin User First Name">
                            </div>
                            <div class=" col-md-6">
                                <label for="adminLastName" class="col-form-label">Admin User Last Name</label>
                                <input kendoTextBox type="text" formControlName="adminLastName" id="adminLastName" class="form-control" placeholder="Admin User Last Name">
                            </div>
                            <div class=" col-md-6">
                                <label for="adminUsername" class="col-form-label">Admin Username</label>
                                <input kendoTextBox type="text" formControlName="adminUsername" id="adminUsername" class="form-control" placeholder="Admin Username">
                            </div>
                            <div class=" col-md-6">
                                <label for="adminPassword" class="col-form-label">Admin Password</label>
                                <input kendoTextBox type="password" formControlName="adminPassword" id="adminPassword" class="form-control" placeholder="Admin Password">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'CDN and File Settings'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <div class="row">
                    <div class="col-sm-12">
                        <h4>CDN Settings</h4>
                    </div>
                    <div class=" col-md-12">
                        <label for="CloudFrontDistributionId" class="col-form-label">CDN Distribution Id</label>
                        <input kendoTextBox type="text" readonly formControlName="cloudFrontDistributionId" id="CloudFrontDistributionId" class="form-control" placeholder="CDN Distribution Id">
                    </div>
                    <div class=" col-md-12">
                        <label for="CloudFrontDistributionDomain" class="col-form-label">CDN Distribution Domain</label>
                        <input kendoTextBox type="text" readonly formControlName="cloudFrontDistributionDomain" id="CloudFrontDistributionDomain" class="form-control" placeholder="CDN Distribution Domain">
                    </div>
                    <div class=" col-md-12">
                        <label for="CustomCdnDomain" class="col-form-label">Custom CDN Domain</label>
                        <input kendoTextBox type="text" formControlName="customCdnDomain" id="CustomCdnDomain" class="form-control" placeholder="Custom CDN Domain">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h4>File Storage Settings</h4>
                    </div>
                    <div class=" col-md-12">
                        <label for="S3BucketName" class="col-form-label">File Bucket Name</label>
                        <input kendoTextBox type="text" formControlName="s3BucketName" id="S3BucketName" class="form-control" placeholder="File Bucket Name">
                    </div>
                    <div class=" col-md-12">
                        <label for="S3BucketDnsName" class="col-form-label">File Bucket DNS Name</label>
                        <input kendoTextBox type="text" readonly formControlName="s3BucketDnsName" id="S3BucketDnsName" class="form-control" placeholder="File Bucket DNS Name">
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Contact Information'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <div class="row" formGroupName="billingAddress">
                    <div class="col-sm-12">
                        <h4>Billing Address <small><a href="javascript:void(0)" (click)="copyFromMailingAddress()">Copy From Mailing Address</a></small></h4>
                        <div class="row">
                            <div class=" col-md-6">
                                <label for="billingAddress_address1" class="col-form-label">Address 1</label>
                                <input kendoTextBox type="text" formControlName="address1" id="billingAddress_address1" class="form-control" placeholder="Address 1">
                            </div>
                            <div class=" col-md-6">
                                <label for="billingAddress_address2" class="col-form-label">Address 2</label>
                                <input kendoTextBox type="text" formControlName="address2" id="billingAddress_address2" class="form-control" placeholder="Address2">
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-md-4">
                                <label for="billingAddress_city" class="col-form-label">City</label>
                                <input kendoTextBox type="text" formControlName="city" id="billingAddress_city" class="form-control" placeholder="City">
                            </div>
                            <div class=" col-md-4">
                                <label for="billingAddress_state" class="col-form-label">State</label>
                                <input kendoTextBox type="text" formControlName="state" id="billingAddress_state" class="form-control" placeholder="State">
                            </div>
                            <div class=" col-md-4">
                                <label for="billingAddress_zipCode" class="col-form-label">Zip Code</label>
                                <input kendoTextBox type="text" formControlName="zipCode" id="billingAddress_zipCode" class="form-control" placeholder="Zip Code">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" formGroupName="mailingAddress">
                    <div class="col-sm-12">
                        <h4>Mailing Address <small><a href="javascript:void(0)" (click)="copyFromBillingAddress()">Copy From Billing Address</a></small></h4>
                        <div class="row">
                            <div class=" col-md-6">
                                <label for="mailingAddress_address1" class="col-form-label">Address 1</label>
                                <input kendoTextBox type="text" formControlName="address1" id="mailingAddress_address1" class="form-control" placeholder="Address 1">
                            </div>
                            <div class=" col-md-6">
                                <label for="mailingAddress_address2" class="col-form-label">Address 2</label>
                                <input kendoTextBox type="text" formControlName="address2" id="mailingAddress_address2" class="form-control" placeholder="Address2">
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-md-4">
                                <label for="mailingAddress_city" class="col-form-label">City</label>
                                <input kendoTextBox type="text" formControlName="city" id="mailingAddress_city" class="form-control" placeholder="City">
                            </div>
                            <div class=" col-md-4">
                                <label for="mailingAddress_state" class="col-form-label">State</label>
                                <input kendoTextBox type="text" formControlName="state" id="mailingAddress_state" class="form-control" placeholder="State">
                            </div>
                            <div class=" col-md-4">
                                <label for="mailingAddress_zipCode" class="col-form-label">Zip Code</label>
                                <input kendoTextBox type="text" formControlName="zipCode" id="mailingAddress_zipCode" class="form-control" placeholder="Zip Code">
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Account Limits'">
            <ng-template kendoTabContent>
                <div class="row" formGroupName="accountLimits">
                    <div class=" col-sm-12">
                        <label for="locations" class="col-form-label">Locations</label>
                        <kendo-numerictextbox class="form-control" formControlName="locations" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="buildings" class="col-form-label">Buildings</label>
                        <kendo-numerictextbox class="form-control" formControlName="buildings" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="rooms" class="col-form-label">Rooms</label>
                        <kendo-numerictextbox class="form-control" formControlName="rooms" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="courses" class="col-form-label">Courses</label>
                        <kendo-numerictextbox class="form-control" formControlName="courses" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="patients" class="col-form-label">Patients</label>
                        <kendo-numerictextbox class="form-control" formControlName="patients" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="checklists" class="col-form-label">Checklists</label>
                        <kendo-numerictextbox class="form-control" formControlName="checklists" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="inventoryItems" class="col-form-label">Inventory Items</label>
                        <kendo-numerictextbox class="form-control" formControlName="inventoryItems" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="simMaintenanceTasks" class="col-form-label">Sim Maintenance Tasks</label>
                        <kendo-numerictextbox class="form-control" formControlName="simMaintenanceTasks" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="labMaintenanceTasks" class="col-form-label">Lab Maintenance Tasks</label>
                        <kendo-numerictextbox class="form-control" formControlName="labMaintenanceTasks" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="scenarios" class="col-form-label">Scenarios</label>
                        <kendo-numerictextbox class="form-control" formControlName="scenarios" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="skills" class="col-form-label">Skills</label>
                        <kendo-numerictextbox class="form-control" formControlName="skills" [min]="1" [max]="999" [format]="'n0'"></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="manikinInventoryItems" class="col-form-label">Manikin Inventory Items</label>
                        <kendo-numerictextbox class="form-control" formControlName="manikinInventoryItems" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="manikinInventoryItemProcedures" class="col-form-label">Manikin Supply Procedures</label>
                        <kendo-numerictextbox class="form-control" formControlName="manikinInventoryItemProcedures" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="communicationBoardActionItems" class="col-form-label">Communication Board Action Items</label>
                        <kendo-numerictextbox class="form-control" formControlName="communicationBoardActionItems" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                    <div class=" col-sm-12">
                        <label for="user" class="col-form-label">Users</label>
                        <kendo-numerictextbox class="form-control" formControlName="users" [min]="1" [max]="999" [format]="'n0'" ></kendo-numerictextbox>
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Application Deployments'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <div class="row">
                    <div (click)="refreshDeploymentData()" title="Refresh deployment data" class="col-xs-12"><button type="button" class="btn btn-primary"><i class="fas fa-sync"></i></button></div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <h3>Organization Application Deployments</h3>
                    </div>
                    <div class="col-md-6">
                    </div>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Metadata'" [disabled]="!editMode">
            <ng-template kendoTabContent>
                <app-metadata [metadata]="dataModel"></app-metadata>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</form>
